var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "cart-info-card h-100 px-6 py-3 px-sm-3 py-sm-3",
      attrs: { flat: "", tile: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "text-body-2 default--text font-weight-bold my-5",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("orders.header.orderNumber")))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("orders.header.status")))])
                  ]),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("orders.header.deliveryStatus")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "text-body-1 font-weight-bold my-5",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c("span", { staticClass: "primary--text" }, [
                      _vm._v("#" + _vm._s(_vm.order.orderId))
                    ])
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("OrderStatusBadge", {
                        staticClass: "default--text",
                        attrs: { order: _vm.order }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _vm.order.deliveryStatusId &&
                      _vm.order.orderStatusId != 10
                        ? _c("OrderStatusDeliveryBadge", {
                            staticClass: "default--text",
                            attrs: { order: _vm.order, disabled: true }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "order-buttons", attrs: { cols: "12" } },
            [
              _vm.order.isEditable
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        outlined: "",
                        depressed: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.editOrder(_vm.order.orderId)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("$edit")]),
                      _vm._v(" " + _vm._s(_vm.$t("orders.button.edit")) + " ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.order.isPayable
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        outlined: "",
                        depressed: ""
                      },
                      on: { click: _vm.showPayment }
                    },
                    [
                      _c("v-icon", [_vm._v("$creditCard")]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("orders.button.retryPayment")) + " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", depressed: "", outlined: "" },
                  on: { click: _vm.printOrder }
                },
                [_vm._v(" " + _vm._s(_vm.$t("orders.button.print")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", outlined: "", depressed: "" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.addAllToCart(_vm.order.orderId)
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("$cart")]),
                  _vm._v(" " + _vm._s(_vm.$t("orders.button.reorder")) + " ")
                ],
                1
              ),
              _vm.order.isDeletable
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error", depressed: "" },
                      on: {
                        click: function($event) {
                          return _vm.deleteOrder(_vm.order.orderId)
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("$delete")]),
                      _vm._v(" " + _vm._s(_vm.$t("orders.button.delete")) + " ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm.order.orderStatusId == 8 || _vm.order.orderStatusId == 18
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        href: `/ebsn/api/order/bill?order_id=${_vm.order.orderId}&type=pdf`,
                        target: "_blank",
                        color: "secondary",
                        depressed: "",
                        outlined: ""
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("orders.button.ddr")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }