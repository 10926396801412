var render = function render(_c, _vm) {
  return _c("div", { staticClass: "price" }, [
    _vm.props.item.unitPriceStandard
      ? _c("div", { staticClass: "old-price" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.parent.$n(_vm.props.item.unitPriceStandard, "currency")
              )
          ),
          _vm.props.item.product.productInfos.TIPOLOGIA != "Pezzo"
            ? _c("span", { staticClass: "weight-unit" }, [
                _vm._v(
                  "/" +
                    _vm._s(
                      _vm.props.showWeightUnitBase
                        ? _vm.props.item.product.productInfos.WEIGHT_UNIT_BASE
                        : _vm.props.item.product.weightUnitDisplay
                    )
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "cur-price",
        class: { "promo-price": _vm.props.item.unitPriceStandard }
      },
      [
        _vm._v(
          " " +
            _vm._s(_vm.parent.$n(_vm.props.item.unitPrice, "currency")) +
            " "
        ),
        _vm.props.item.product.productInfos.TIPOLOGIA != "Pezzo"
          ? _c("span", { staticClass: "weight-unit" }, [
              _vm._v(
                "/" +
                  _vm._s(
                    _vm.props.showWeightUnitBase
                      ? _vm.props.item.product.productInfos.WEIGHT_UNIT_BASE
                      : _vm.props.item.product.weightUnitDisplay
                  )
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }