var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.order
    ? _c(
        "v-container",
        {
          staticClass: "order-detail d-flex flex-column px-0",
          attrs: { fluid: "" }
        },
        [
          _c("CategoryTitle", { attrs: { category: _vm.category } }),
          _vm.showPayment
            ? _c(
                "div",
                { staticClass: "pa-3 grey lighten-3 mb-5 rounded-sm" },
                [
                  _c(
                    "span",
                    { staticClass: "d-block text-h2 line-height-1 mt-3" },
                    [_vm._v(_vm._s(_vm.$t("order.retryPayment")))]
                  ),
                  _c("PaymentTypeList", {
                    attrs: {
                      mode: "order",
                      "order-id": _vm.order.orderId,
                      options: _vm.paymentTypeListOptions
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "mb-3", attrs: { justify: "space-between" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("CartInfoAddressCard", {
                    staticClass: "w-100 cart-info-timeslot-card",
                    attrs: { shippingAddress: _vm.order.shippingAddress }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _vm.order
                    ? _c("CartInfoTimeslotCard", {
                        staticClass: "w-100 cart-info-timeslot-card",
                        attrs: {
                          shippingAddress: _vm.order.shippingAddress,
                          timeslot: _vm.order.timeslot,
                          value: _vm.value
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "8" } },
                [
                  _c("OrderDetailInfo", {
                    attrs: { order: _vm.order },
                    on: {
                      addAllToCart: _vm.addAllToCart,
                      deleteOrder: _vm.deleteOrder,
                      editOrder: _vm.editOrder,
                      showPayment: function($event) {
                        _vm.showPayment = !_vm.showPayment
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "4" } },
                [
                  _c("CheckoutSummary", {
                    attrs: {
                      orderCart: _vm.order,
                      title: _vm.$t(
                        `order.paymentType[${this.order.paymentTypeId}]`
                      ),
                      delivered: _vm.delivered
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.isSsoUser
            ? _c(
                "v-row",
                { attrs: { justify: "center", align: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8" } },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("order.chageDate")))]),
                      _c("label", {
                        domProps: {
                          innerHTML: _vm._s(`${_vm.$t("order.newDate")}`)
                        }
                      }),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              "prepend-icon": "$calendar",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.order.timeslot.date,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.order.timeslot,
                                                  "date",
                                                  $$v
                                                )
                                              },
                                              expression: "order.timeslot.date"
                                            }
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2056493648
                          ),
                          model: {
                            value: _vm.menuNewDate,
                            callback: function($$v) {
                              _vm.menuNewDate = $$v
                            },
                            expression: "menuNewDate"
                          }
                        },
                        [
                          _c("v-date-picker", {
                            attrs: {
                              min: _vm._f("dayjs")(
                                new Date(),
                                "format",
                                "YYYY-MM-DD"
                              ),
                              locale: "it-IT",
                              "no-title": ""
                            },
                            on: {
                              input: function($event) {
                                _vm.menuNewDate = false
                              }
                            },
                            model: {
                              value: _vm.order.timeslot.date,
                              callback: function($$v) {
                                _vm.$set(_vm.order.timeslot, "date", $$v)
                              },
                              expression: "order.timeslot.date"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 mr-md-2 mb-4 text-uppercase",
                          attrs: {
                            color: "secondary",
                            depressed: "",
                            outlined: "",
                            block: ""
                          },
                          on: { click: _vm.changeDateOrder }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("order.chageDateBtn")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.order.timeslot.ChangeDateOrder) + " "),
          _c("OrderDetailTable", {
            staticClass: "mt-4 mt-sm-12",
            attrs: {
              suborders: _vm.order.suborders,
              order: _vm.order,
              delivered: _vm.delivered
            }
          }),
          _c(
            "v-row",
            {
              staticClass:
                "backbtn-container mt-5 justify-center justify-sm-start",
              attrs: { "no-gutters": "" }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    outlined: "",
                    to: { name: "Orders" }
                  }
                },
                [
                  _c("v-icon", { staticClass: "mr-3" }, [_vm._v("$prev")]),
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("orders.button.backToOrders"))
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }