var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qty-box order-detail-quantity" }, [
    _c("span", { staticClass: "val" }, [
      _vm._v(" " + _vm._s(_vm.deliveredVal) + " " + _vm._s(_vm.unit) + " ")
    ]),
    _c("span", { staticClass: "small" }, [_vm._v(_vm._s(_vm.quantityPerUnit))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }