var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { "right:disabled": "disabled" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on, attrs }) {
            return [
              _c(
                "span",
                { staticClass: "d-flex secondary--text" },
                [
                  _vm._v(" " + _vm._s(_vm.orderStatus) + " "),
                  !_vm.disabled
                    ? _c(
                        "v-icon",
                        _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                        [_vm._v("$info")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [_c("span", [_vm._v(_vm._s(_vm.orderStatusTooltip))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }