<template>
  <v-tooltip right:disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <span class="d-flex secondary--text">
        {{ orderStatus }}
        <v-icon v-bind="attrs" v-on="on" v-if="!disabled">$info</v-icon>
      </span>
    </template>
    <span>{{ orderStatusTooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    order: { type: Object, required: true },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    orderStatus() {
      if (
        this.order.orderStatusId != 8 ||
        (this.order.orderStatusId == 8 &&
          (!this.order.deliveryStatusId || this.order.deliveryStatusId == 3))
      ) {
        return global.vm.$t(
          `order.orderStatusDescr[${this.order.orderStatusId}]`
        );
      } else if (
        this.order.deliveryStatusId &&
        this.sorder.orderStatusId != 10
      ) {
        return global.vm.$t(
          `order.deliveryStatusDescr[${this.order.deliveryStatusId}]`
        );
      } else {
        return global.vm.$t(
          `order.orderStatusDescr[${this.order.orderStatusId}]`
        );
      }
    },
    orderStatusTooltip() {
      if (
        this.order.orderStatusId != 8 ||
        (this.order.orderStatusId == 8 &&
          (!this.order.deliveryStatusId || this.order.deliveryStatusId == 3))
      ) {
        return global.vm.$t(
          `order.orderStatusTooltip[${this.order.orderStatusId}]`
        );
      } else if (
        this.order.deliveryStatusId &&
        this.sorder.orderStatusId != 10
      ) {
        return global.vm.$t(
          `order.orderStatusTooltip[${this.order.deliveryStatusId}]`
        );
      } else {
        return global.vm.$t(
          `order.orderStatusTooltip[${this.order.orderStatusId}]`
        );
      }
    }
  }
};
</script>
