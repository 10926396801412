var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "order-detail-table" },
    [
      !_vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-row",
            {
              staticClass: "px-5 order-table-header secondary--text",
              attrs: { "no-gutters": "" }
            },
            [
              _c("v-col", { attrs: { cols: "6" } }, [
                _c("strong", [_vm._v(_vm._s(_vm.$t("orders.header.product")))])
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex justify-center", attrs: { cols: "2" } },
                [_c("strong", [_vm._v(_vm._s(_vm.$t("orders.header.qty")))])]
              ),
              _c(
                "v-col",
                { staticClass: "d-flex justify-center", attrs: { cols: "2" } },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("orders.header.unitPrice")))
                  ])
                ]
              ),
              _c(
                "v-col",
                { staticClass: "d-flex justify-center", attrs: { cols: "2" } },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("orders.header.totalPrice")))
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.suborders, function(suborder) {
        return _c(
          "v-list",
          { key: suborder.suborderId },
          _vm._l(suborder.suborderItems, function(item) {
            return _c(
              "v-list-item",
              { key: item.itemId, staticClass: "py-4" },
              [
                _c("order-detail-row", {
                  attrs: { item: item, order: _vm.order }
                })
              ],
              1
            )
          }),
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }